<template>
  <div>
    <div v-if="loadingTableTimeSolt" class="mb-3 mt-2">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="mt-2 mb-0 font-weight-bold text-subtitle-1 text-center" cols="12">
          Getting your timeslot list
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="light-blue darken-4" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <!--  
        <pre>{{ arrCurrentTime.term }}</pre>
        <pre>{{ selYear }}</pre>
        <pre>{{ parseInt(switchShowTerm + 1) }}</pre>
      -->
      <div class="mt-2 mb-3">
        <small class="mt-2 mb-0 text--disabled">Timezone :: {{ moment(serverTime).format() }}</small>
        <br />
        <small class="mt-2 mb-0 text--disabled">sectionId :: {{ timeslots[0].sectionId }}</small>
      </div>
      <v-divider class="mt-2 mb-2"></v-divider>
      <!-- <pre>{{ selectedTaught }}</pre> -->
      <v-data-table :headers="headers" :items="timeslots[0].sectionSlots" class="elevation-1" disable-pagination
        hide-default-footer>
        <!-- action btn Taught all & Makeup -->
        <template v-slot:top>
          <v-row class="mb-0" justify="end">
            <v-col class="text-right" cols="12" md="10">
              <span v-if="$store.state.role.RulesUser.permissions === 'fulltime'" class="mr-3">
                <span v-if="checkAfterBtnTaughtAll(
                  arrCurrentTime.year,
                  arrCurrentTime.term,
                  selYear,
                  parseInt(switchShowTerm + 1)
                )
                ">
                  <span v-if="timeslots[0].SetCourse.length != 0">
                    <v-btn :disabled="selectedTaught.length == 0 ||
                      timeslots[0].SetCourse[0].status === 'pre-setting'
                      " :dark="selectedTaught.length != 0 &&
                        timeslots[0].SetCourse[0].status !== 'pre-setting'
                        " class="font-weight-bold" dense color="teal darken-2" @click="openAllTaught"><v-icon
                        class="mr-2">mdi-table-check</v-icon>Taught
                      Selected</v-btn>
                  </span>
                  <span v-else>
                    <v-btn color="teal darken-2" class="font-weight-bold" disabled>
                      <v-icon class="mr-2">mdi-table-check</v-icon>Taught Selected
                    </v-btn>
                  </span>
                </span>
                <span v-else>
                  <span style="color:red;font-size: 14px;font-weight: 500;">
                    This request is for the preious payment cycle. Please contact Khun Piraporn, OAA staff, to confirm
                    your payment request. Email: <a target="_blank"
                      href="mailto:piraporn.nuc@mahidol.edu">piraporn.nuc@mahidol.edu</a> or Ext. 4341<br />
                  </span>
                </span>
              </span>
              <!-- check list all taught -->
              <!-- <pre>{{ selectedTaught }}</pre> -->
              <span v-if="arrMsgBlock.length != 0">
                <span v-if="arrMsgBlock[0].makeup_btn">
                  <v-btn @click="openDialog({
                    action: 'makeUp',
                    sectionId: timeslots[0].sectionId,
                    course: `${timeslots[0].course.courseCode} - ${timeslots[0].course.courseNameEn}`,
                  })
                    " class="font-weight-bold mr-2" dark dense color="light-blue darken-4"><v-icon
                      class="mr-2">mdi-table-large-plus</v-icon>Make
                    up</v-btn>
                </span>
                <span v-else>
                  <v-btn color="teal darken-2" class="font-weight-bold" disabled>
                    <v-icon class="mr-2">mdi-table-large-plus</v-icon>Make up
                  </v-btn>
                </span>
              </span>
              <span v-else>
                <v-btn @click="openDialog({
                  action: 'makeUp',
                  sectionId: timeslots[0].sectionId,
                  course: `${timeslots[0].course.courseCode} - ${timeslots[0].course.courseNameEn}`,
                })
                  " class="font-weight-bold mr-2" dark dense color="light-blue darken-4"><v-icon
                    class="mr-2">mdi-table-large-plus</v-icon>Make
                  up</v-btn>
              </span>
            </v-col>
          </v-row>
          <!-- setting anouncment -->
          <v-row v-if="arrMsgBlock.length != 0" class="mt-0 mb-0" justify="end">
            <v-col class="text-right" cols="4" md="4">
              <v-alert class="mb-0 mt-0" text dense variant="tonal" type="info">
                <div class="text-left">
                  <p class="mt-1 mb-0 font-weight-black">
                    [ Announcement ] :
                  </p>
                  <div class="mt-1 mb-1 d-flex w-100 justify-space-between">
                    <p class="f-a-20 mt-0 mb-0 font-weight-black">&nbsp;</p>
                    <p style="font-size: 14px;" class="mt-0 mb-0 font-weight-black">{{ arrMsgBlock[0].msg }}</p>
                  </div>
                  <!--  
                    <div class="mt-0 mb-1 d-flex w-100 justify-space-between">
                      <p class="mt-0 mb-0 text--disabled f-a-18">{{ moment(arrMsgBlock[0].time_start).format('DD / MMM / YYYY') }} to {{ moment(arrMsgBlock[0].time_end).format('DD / MMM / YYYY') }}</p>
                    </div>
                  -->
                  <!-- <pre>{{ arrMsgBlock[0].makeup_btn }}</pre> -->
                </div>
              </v-alert>
            </v-col>
          </v-row>
          <!-- setting anouncment -->
        </template>
        <!-- action btn Taught all & Makeup -->
        <!-- selected box -->
        <template v-slot:header.select-data="{ header }">
          <div v-if="$store.state.role.RulesUser.permissions === 'fulltime'">
            <v-checkbox v-model="selectAllTaught"></v-checkbox>
          </div>
        </template>
        <template #item.select-data="{ item, index }">
          <!-- {{ $store.state.role.RulesUser.role }} -->
          <div v-if="item.instructor !== null &&
            item.instructor !== 'Midterm' &&
            item.instructor !== 'Final'
          ">
            <div v-if="$store.state.role.RulesUser.username ===
              item.instructor.instructorCode &&
              $store.state.role.RulesUser.permissions === 'fulltime'
            ">
              <div v-if="item.status === 'w' &&
                timeSlotDayClick(item.date, item.startTime, item.endTime, serverTime)
              ">
                <v-checkbox v-model="selectedTaught"
                  :value="`${item.sectionId}::${item.sectionSlotId}::${item.date}`"></v-checkbox>
              </div>
            </div>
            <!--  
              item.startTime item.endTime
              diffDayClick(
                  moment(
                    `${moment(item.date).format('YYYY-MM-DD')}T${item.startTime
                    }`
                  )
                    .locale('th')
                    .format('LT'),
                  moment(item.date).format('YYYY-MM-DD'),
                  moment(serverTime).format('YYYY-MM-DD'),
                  item.startTime, item.endTime
                )
            -->
          </div>
        </template>
        <!-- selected box -->
        <!-- list data -->
        <template #item.number="{ item, index }">
          <!-- <p class="mt-0 mb-0">{{ item.sectionSlotId }}</p> -->
          <!-- v-if="item.status !== 'c'" -->
          <div class="font-weight-bold text-h5" v-if="!['null', null, 'c', 'r', 'n/a', 'N/A'].includes(item.status)">
            {{ timeSlotDayClick(item.date, item.startTime, item.endTime, serverTime) ? 'Passed' : 'Scheduled' }}
            <!-- 
              {{
                diffDayClick(
                  moment(
                    `${moment(item.date).format("YYYY-MM-DD")}T${item.startTime}`
                  )
                    .locale("th")
                    .format("LT"),
                  moment(item.date).format("YYYY-MM-DD"),
                  moment(serverTime).format("YYYY-MM-DD"),
                  item.startTime, item.endTime
                )
                ? "Passed"
                : "Scheduled"
              }} 
            -->
          </div>
          <div class="font-weight-bold text--disabled text-h5" v-else>
            {{ item.status === 'r' ? '-' : '' }}
          </div>
        </template>
        <template #item.details="{ item }">
          <p class="mt-1 mb-0" :class="{
            'text--disabled': ['r', 'c', null, 'n/a', 'N/A'].includes(item.status),
          }">
            <v-avatar class="mr-2" rounded size="35" :color="['r', 'c', null, 'n/a', 'N/A'].includes(item.status) ? 'grey lighten-2' : colorDayCheck(item.day)
              ">
              <span class="font-weight-bold">{{ item.day }}</span>
            </v-avatar>
            <strong>{{ moment(item.date).format("DD MMM YYYY") }}</strong>
          </p>
          <p :class="{
            'text--disabled': ['r', 'c', null, 'n/a', 'N/A'].includes(item.status),
          }" class="mt-0 mb-1 font-weight-bold">
            {{
              `${item.startTime.split(":")[0]}:${item.startTime.split(":")[1]}`
            }}
            -
            {{ `${item.endTime.split(":")[0]}:${item.endTime.split(":")[1]}` }}
          </p>
          <div class="mt-1">
            <!-- <small class="text--disabled">sectionId : {{ item.sectionId }} </small> <br> -->
            <small class="text--disabled">SlotId : {{ item.sectionSlotId }} </small>
          </div>
        </template>
        <template #item.type="{ item }">
          <p :class="{ 'text--disabled': ['r', 'c', null, 'n/a', 'N/A'].includes(item.status) }"
            class="text-capitalize font-weight-bold mt-0 mb-0">
            {{ item.teachingType }}
          </p>
        </template>
        <template #item.room="{ item }">
          <p :class="{ 'text--disabled': ['r', 'c', null, 'n/a', 'N/A'].includes(item.status) }"
            class="mt-2 mb-0 font-weight-bold">
            {{ item.room === 'No Classroom' ? 'No used / No Classroom' : item.room }}
          </p>
          <p :class="{ 'text--disabled': ['r', 'c', null, 'n/a', 'N/A'].includes(item.status) }" class="mt-0 mb-0"
            v-if="item.instructor !== null">
            {{ item.instructor.title }} {{ item.instructor.name }}
            {{ item.instructor.lastName }}
          </p>
        </template>
        <template #item.status="{ item }">
          <v-chip class="ma-2" small label :color="item.status === 'w'
            ? 'yellow darken-3'
            : item.status === 'p'
              ? 'teal lighten-4'
              : ['r', 'c', null, 'n/a', 'N/A'].includes(item.status)
                ? 'grey lighten-4'
                : 'grey lighten-4'
            ">
            <span class="text-h6 font-weight-bold">
              <span v-if="item.teachingType === 'Midterm' ||
                item.teachingType === 'Final'
              ">
                <span v-if="item.status === 'w'">Waiting</span>
                <span v-if="item.status === 'p'">
                  <span v-if="item.absentInstructor !== null">{{
                    item.absentInstructor ? "Absent / joint with other section" : "Present"
                  }}</span>
                </span>
                <span v-if="item.status === 'r'">Disable exam</span>
                <span v-if="item.status === 'c'">Absent</span>
                <span v-if="item.status === null || item.status === ''">N/A</span>
              </span>
              <span v-else>
                {{
                  item.status === "w"
                    ? "Waiting"
                    : item.status === "p"
                      ? "Taught"
                      : item.status === "c"
                        ? "Cancelled"
                        : "N/A"
                }}
              </span>
            </span>
          </v-chip>
        </template>
        <template #item.action="{ item, index }">
          <!-- {{ $store.state.role.RulesUser.username }} -->
          <!-- {{ item.instructor.instructorCode }} -->
          <!-- {{ $store.state.role.RulesUser.role }} -->
          <!-- for parttime only -->
          <div v-if="$store.state.role.RulesUser.permissions !== 'fulltime' &&
            item.instructor !== null
          ">
            <v-btn v-if="(item.status === null || item.status === 'w') &&
              item.teachingType !== 'Midterm' &&
              item.teachingType !== 'Final'
            " elevation="2" class="font-weight-bold text-capitalize" dark small color="red" value="c" @click="openDialog({
              action: 'checkSlot',
              sectionId: item.sectionId,
              sectionSlotId: item.sectionSlotId,
              timeslot: `${item.day} ${moment(item.date).format(
                'DD-MM-YYYY'
              )} , ${item.startTime} - ${item.endTime}`,
              course: `${timeslots[0].course.courseCode}${timeslots[0].course.courseNameEn}`,
              type: 'Cancel',
              status: 'c',
              dataSlot: item.date,
            })
              ">
              Cancel
            </v-btn>
          </div>
          <!-- for fulltime only -->
          <div v-else>
            <div v-if="item.instructor !== null">
              <v-btn-toggle v-if="(item.status === null || item.status === 'w') &&
                $store.state.role.RulesUser.username ===
                item.instructor.instructorCode
              " class="text-h6 font-weight-bold" dense v-model="valueSetting[item.sectionSlotId]">
                <!-- Yes click Taught : Date,true : load,save : role,testerGroup : fulltime -->
                <!--  
                  diffDayClick(
                    moment(
                      `${moment(item.date).format('YYYY-MM-DD')}T${item.startTime
                      }`
                    )
                      .locale('th')
                      .format('LT'),
                    moment(item.date).format('YYYY-MM-DD'),
                    moment(serverTime).format('YYYY-MM-DD'),
                    item.startTime, item.endTime
                  )
                -->
                <v-btn v-if="timeSlotDayClick(item.date, item.startTime, item.endTime, serverTime) &&
                  timeslots[0].SetCourse.length != 0 &&
                  timeslots[0].SetCourse[0].status === 'save-setting'
                " elevation="2" class="font-weight-bold text-capitalize" dark small color="teal darken-3" value="p"
                  @click="openDialog({
                    action: 'checkSlot',
                    sectionId: item.sectionId,
                    sectionSlotId:
                      item.teachingType === 'Midterm' ||
                        item.teachingType === 'Final'
                        ? item.examinationId
                        : item.sectionSlotId,
                    timeslot: `${item.day} ${moment(item.date).format(
                      'DD-MM-YYYY'
                    )} , ${item.startTime} - ${item.endTime}`,
                    course: `${timeslots[0].course.courseCode}${timeslots[0].course.courseNameEn}`,
                    type: 'Taught',
                    status:
                      item.teachingType === 'Midterm' ||
                        item.teachingType === 'Final'
                        ? item.absentInstructor
                          ? 'c'
                          : 'p'
                        : 'p',
                    dataSlot: item.date,
                    teachingType: item.teachingType,
                  })
                    ">
                  {{
                    item.teachingType === "Midterm" ||
                      item.teachingType === "Final"
                      ? item.absentInstructor
                        ? "Absent / joint with other section"
                        : "Present"
                      : "Taught"
                  }}
                </v-btn>
                <!-- Not click Taught -->
                <v-btn v-else elevation="2" class="font-weight-bold text-capitalize" small color="teal darken-3"
                  disabled>
                  {{
                    item.teachingType === "Midterm" ||
                      item.teachingType === "Final"
                      ? item.absentInstructor
                        ? "Absent / joint with other section"
                        : "Present"
                      : "Taught"
                  }}
                </v-btn>
                <!-- btn cancel Taught -->
                <v-btn v-if="item.teachingType !== 'Midterm' &&
                  item.teachingType !== 'Final'
                " elevation="2" class="font-weight-bold text-capitalize" dark small color="red" value="c" @click="openDialog({
                  action: 'checkSlot',
                  sectionId: item.sectionId,
                  sectionSlotId: item.sectionSlotId,
                  timeslot: `${item.day} ${moment(item.date).format(
                    'DD-MM-YYYY'
                  )} , ${item.startTime} - ${item.endTime}`,
                  course: `${timeslots[0].course.courseCode}${timeslots[0].course.courseNameEn}`,
                  type: 'Cancel',
                  status: 'c',
                  dataSlot: item.date,
                  teachingType: item.teachingType,
                })
                  ">
                  Cancel
                </v-btn>
                <!-- btn cancel Exam for instructor not present -->
                <v-btn v-if="(item.teachingType === 'Midterm' ||
                  item.teachingType === 'Final') &&
                  !item.absentInstructor
                " elevation="2" class="font-weight-bold text-capitalize" dark small color="red" value="c" @click="openDialog({
                  action: 'checkSlot',
                  sectionId: item.sectionId,
                  sectionSlotId: item.sectionSlotId,
                  timeslot: `${item.day} ${moment(item.date).format(
                    'DD-MM-YYYY'
                  )} , ${item.startTime} - ${item.endTime}`,
                  course: `${timeslots[0].course.courseCode}${timeslots[0].course.courseNameEn}`,
                  type: 'Cancel',
                  status: 'c',
                  dataSlot: item.date,
                  teachingType: item.teachingType,
                })
                  ">
                  Absent /joint with other section
                </v-btn>
              </v-btn-toggle>
              <div class="text-center" v-else>
                <!-- {{ item.Apayment }} -->
                <a class="f-300 text-decoration-underline" v-if="item.Apayment.length != 0 && item.Apayment !== '-'"
                  @click="gotoBill(
                    item.Apayment[0].paid_month,
                    item.Apayment[0].paid_year
                  )
                    ">
                  <p class="mb-0">
                    {{
                      item.Apayment !== "-"
                        ? `${moment()
                          .month(parseInt(item.Apayment[0].paid_month - 1))
                          .format("MMMM")} ${item.Apayment[0].paid_year}`
                        : ""
                    }}
                  </p>
                  <small class="mb-1" v-if="item.absentInstructor !== null &&
                    item.absentInstructor !== ''
                  ">
                    ( {{ item.absentInstructor ? "Absent / joint with other section" : "Present" }} )
                  </small>
                </a>
              </div>
            </div>
          </div>
        </template>
        <template #item.remark="{ item, index }">
          <!-- btn add remark -->
          <!--  
            <v-btn
              v-if="item.remark === null || item.remark === ''"
              depressed
              small
              color="primary"
              text
              @click="
                openAddOaaRemark({
                  sectionId: item.sectionId,
                  sectionSlotId: item.sectionSlotId,
                  course: `${timeslots[0].course.courseCode}${timeslots[0].course.courseNameEn}`,
                })
              "
            >
              Add remark<v-icon class="ml-1" small>mdi-cursor-pointer</v-icon>
            </v-btn>
          -->
          <!-- text remark -->
          <!-- <div v-else></div> -->
          <p class="text-justify mt-0 mb-5 text-r">
            <span style="font-size: 14px" v-html="item.remark"></span>
          </p>
        </template>
        <template #item.configRow="{ item, index }">

          
          <!--  
            <pre>Account Login : {{ $store.state.role.RulesUser.username }}</pre>
            <pre>-------</pre>
           
            <pre>-------</pre>
            <pre>CO-instructor : {{ timeslots[0].coInstructors }}</pre>
          -->

          <div v-if="timeslots[0].mainInstructor.instructorCode === $store.state.role.RulesUser.username">
            <div v-if="['w'].includes(item.status) && ['lecture','lab'].includes(item.teachingType)">
              <v-btn  depressed small color="primary" text @click="openModalUpdateTimeSlot(
                {
                  sectionId: item.sectionId,
                  sectionSlotId: item.sectionSlotId,
                  rowItem: item,
                }
              )">
                <v-icon class="ml-1">mdi-pencil-box-multiple</v-icon>
              </v-btn>
            </div>
          </div>

         
        </template>
      </v-data-table>
    </div>
    <!-- dialog - Config -->
    <v-dialog v-model="dialogChangeTimeSlot" :width="550" :retain-focus="false" persistent>
      <v-card>
        <v-card-text class="text-left text-h4 mt-0 mb-0 grey lighten-2 font-weight-bold">
          <v-icon class="mr-2">mdi-pencil-box-multiple</v-icon>Edit Slot
        </v-card-text>

        <div class="pa-3" v-if="Object.keys(objSlotDataForUpdate).length != 0">
          <!-- <pre>{{ objSlotDataForUpdate }}</pre> -->
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Slot ID :<strong class="ml-2">{{ objSlotDataForUpdate.sectionId
                  }}</strong></v-list-item-title>
              <v-list-item-title class="mt-3">Timeslot :<strong class="ml-2">{{ objSlotDataForUpdate.sectionSlotId
                  }}</strong></v-list-item-title>
              <v-list-item-title class="mt-3 mb-3">Detail :</v-list-item-title>
              <v-list-item-subtitle class="text-dark">
                <strong class="font-weight-bold mr-2">[ {{ objSlotDataForUpdate.rowItem.day }} ] : {{
                  moment(objSlotDataForUpdate.rowItem.date).format('DD / MM / YYYY') }}</strong>
                Time
                <strong class="ml-2 font-weight-bold">{{ objSlotDataForUpdate.rowItem.startTime }} - {{
                  objSlotDataForUpdate.rowItem.endTime }}</strong>
              </v-list-item-subtitle>
              <v-list-item-title class="mt-3 mb-3 red--text"><strong>Lecture :</strong></v-list-item-title>
              <v-list-item-subtitle>
                <!-- <pre>{{ optionLecture }}</pre> -->
                <v-select outlined dense :items="optionLecture" v-model="newLectureChange"></v-select>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="errorMsgCaseUpdateSlot.length != 0">
                <p style="font-size: 14px;" class="font-weight-bold red--text mt-0 mb-0">{{ errorMsgCaseUpdateSlot[0] }}</p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="font-weight-bold mb-1" color="light-blue darken-4" dense :dark="!loadingBtnUpdateSlot"
              :disabled="loadingBtnUpdateSlot || newLectureChange === null" @click="updateLectureInSlot(objSlotDataForUpdate)">
              <v-progress-circular v-if="loadingBtnUpdateSlot" :size="20" color="white" indeterminate
                class="mr-2"></v-progress-circular>
              Submit
            </v-btn>
            <v-btn class="font-weight-bold mb-1" color="" dense dark @click="closeModalUpdateTimeSlot">
              Close
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <!-- dialog - A -->
    <v-dialog v-model="dialogTimeslot" :width="dataConfirm.action !== 'checkSlot' ? 850 : 550" :retain-focus="false"
      persistent>
      <v-card>
        <v-card-text v-if="dataConfirm.action === 'checkSlot'"
          class="text-left text-h4 mt-0 mb-0 grey lighten-2 font-weight-bold">
          Confirm
        </v-card-text>
        <!-- Dialog - Taught slot -->
        <v-card-text v-if="dataConfirm.action === 'checkSlot'">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Slot ID :<strong class="ml-2">{{
                dataConfirm.sectionSlotId
                  }}</strong></v-list-item-title>
              <v-list-item-title class="mt-3">Course :</v-list-item-title>
              <v-list-item-title class="mt-3"><strong class="text-h4 mt-0 mb-0">{{
                dataConfirm.course
                  }}</strong></v-list-item-title>
              <v-list-item-title class="mt-3">Timeslot :</v-list-item-title>
              <v-list-item-subtitle><strong :class="{
                'text-decoration-line-through': dataConfirm.status === 'c',
              }" class="text-h4 mt-0 mb-0 black--text">{{ dataConfirm.timeslot }}</strong></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <!-- Dialog - Makeup Class -->
        <v-card-text v-if="dataConfirm.action === 'makeUp'">
          <v-chip class="" small label color="yellow darken-3">#Section ID : {{ dataConfirm.sectionId }}</v-chip>
          <div class="mb-3 d-flex justify-lg-space-between">
            <p class="text-h5 mb-0 mt-0">
              Course :<strong class="ml-2 text-h4 font-weight-bold">{{
                dataConfirm.course
              }}</strong>
            </p>
            <small class="mb-2 mt-0">
              Timezone :: {{ moment(serverTime).format() }}
            </small>
          </div>
          <!-- error -->
          <div class="mt-2 mb-2" v-if="objMsgCase.length != 0">
            <v-alert dense class="font-weight-bold" color="red" text>
              <div class="text--h4 font-weight-bold mb-1">
                {{ objMsgCase[0].message }}
              </div>
            </v-alert>
          </div>
          <!-- error -->
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <!-- teaching type -->
              <span class="text-h5 pa-0 mt-0 mb-0 font-weight-bold">Teaching Type </span>
              <v-autocomplete :items="teachingType" v-model="formMakeup.type" clearable dense outlined
                class="mb-0"></v-autocomplete>
              <span class="text-h5 pa-0 mt-0 mb-0 font-weight-bold">Date</span>
              <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined :value="computedDateFormattedMomentjs" dense readonly v-bind="attrs" v-on="on"
                    @click:clear="formMakeup.dateMakeup = null"></v-text-field>
                </template>
                <v-date-picker :min="moment().format('YYYY-MM-DD')" v-model="formMakeup.dateMakeup"
                  @change="menu1 = false"></v-date-picker>
              </v-menu>
              <!-- time start -->
              <span class="text-h5 mt-0 mb-0 font-weight-bold">Time Start</span>
              <v-row>
                <v-col cols="5">
                  <v-autocomplete :items="arrHour" v-model="formMakeup.selectHour_start" @change="getRoomMakeup" dense
                    outlined persistent-hint hint="Hour"></v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-center"><strong>:</strong></v-col>
                <v-col cols="5">
                  <v-autocomplete :items="arrMinuteStart" v-model="formMakeup.selectMinute_start"
                    @change="getRoomMakeup" dense outlined persistent-hint hint="Minute"></v-autocomplete>
                </v-col>
              </v-row>
              <!-- time end  -->
              <span class="text-h5 mt-0 mb-0 font-weight-bold">Time End</span>
              <v-row>
                <v-col cols="5">
                  <v-autocomplete :items="arrHour" v-model="formMakeup.selectHour_end" @change="getRoomMakeup" dense
                    outlined persistent-hint hint="Hour"></v-autocomplete>
                </v-col>
                <v-col cols="2" class="text-center"><strong>:</strong></v-col>
                <v-col cols="5">
                  <v-autocomplete :items="arrMinuteEnd" v-model="formMakeup.selectMinute_end" @change="getRoomMakeup"
                    dense outlined persistent-hint hint="Minute"></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <!-- remark -->
              <span class="text-h5 mt-0 mb-0 font-weight-bold">Remark to OAA Only</span>
              <v-textarea v-model="formMakeup.remarkAdd" outlined></v-textarea>
              <!-- room -->
              <span class="text-h5 pa-0 mt-0 mb-0 font-weight-bold">
                Room
              </span>
              <div v-if="isLoading">
                <p class="mt-0 mb-0">loading Room List ...</p>
              </div>
              <div v-if="arrRoom.length != 0">
                <v-autocomplete :items="arrRoom" v-model="formMakeup.roomId" :loading="isLoading" dense outlined
                  persistent-hint hint="Choose the room you want to teach">
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong class="mr-2">{{ data.item.text }}</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        RoomType : {{ data.item.roomType }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Capacity :
                        {{ data.item.capacity }} Examination Capacity :
                        {{ data.item.examinationCapacity }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-alert class="mb-0 mt-0" text dense color="blue">
                  <p class="f-sm mt-0 mb-0">Remarks:</p>
                  <p class="f-sm mt-0 mb-0">
                    1. Please check the room capacity before making a
                    reservation.
                  </p>
                  <p class="f-sm mt-0 mb-0">
                    2. Select rooms in the MUIC Building 1 first, especially on
                    Saturdays and Sundays.
                  </p>
                  <p class="f-sm mt-0 mb-0">
                    3. No classes can be conducted after 6 P.M. in the
                    Aditayathorn Building. All make up classes after 6 P.M. must
                    be conducted in the old building.
                  </p>
                </v-alert>
              </div>
              <div v-else>
                <v-alert class="mb-0 mt-0" text dense color="blue">
                  <p class="f-sm mt-0 mb-0">
                    Please specify teaching time and teaching type to find a
                    room
                  </p>
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <pre>{{ formMakeup }}</pre> -->
        <!-- error log -->
        <v-card-text class="mt-0 mb-1" v-if="errorMsg !== null">
          <v-alert class="font-weight-bold mt-0 mb-0" dense text color="deep-orange darken-2 lighten-4">
            <p class="mt-0 mb-1">{{ errorMsg }}</p>
            <div v-for="msg in errorMsgsData" :key="msg.timestamp">
              <p v-if="msg.msg !== null" class="mt-0 mb-0">{{ msg.msg }}</p>
            </div>
          </v-alert>
        </v-card-text>
        <!-- action dialog -->
        <!-- {{ formMakeup.dateMakeup }} - {{ moment(formMakeup.dateMakeup).format("dd") }} -->
        <!-- <pre>{{ formMakeup }}</pre> -->
        <!-- <p>---- > {{ isBlockRoom }}</p> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="mt-0 mb-0 mr-2" v-if="dataConfirm.action === 'makeUp'">
            <v-btn v-if="!isFormMakup" class="font-weight-bold mb-1" dense depressed disabled>
              Submit
            </v-btn>
            <v-btn v-else @click="submitMakeupClass(dataConfirm.sectionId)" class="font-weight-bold mb-1"
              color="light-blue darken-4" dense :dark="!loadingBtnAc" :disabled="loadingBtnAc">
              <v-progress-circular v-if="loadingBtnAc" :size="20" color="white" indeterminate
                class="mr-2"></v-progress-circular>
              Submit
            </v-btn>
          </div>
          <v-btn v-if="dataConfirm.action === 'checkSlot'" @click="setSlot(
            dataConfirm.sectionId,
            dataConfirm.sectionSlotId,
            dataConfirm.status,
            dataConfirm.dataSlot,
            dataConfirm.teachingType
          )
            " class="font-weight-bold mb-1" color="light-blue darken-4" dense :dark="!loadingBtnAc"
            :disabled="loadingBtnAc">
            <v-progress-circular v-if="loadingBtnAc" :size="20" color="white" indeterminate
              class="mr-2"></v-progress-circular>
            <strong class="ml-1">Submit</strong>
            <!-- <strong class="ml-1">{{ dataConfirm.type }}</strong> -->
          </v-btn>
          <v-btn @click="closeDialog" class="font-weight-bold mb-1" color="" dense dark>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog all taught -->
    <v-dialog v-model="dialogAllTaught" width="550" :retain-focus="false" persistent>
      <v-card>
        <v-card-text class="text-left text-h4 mt-0 mb-0 grey lighten-2 font-weight-bold">
          Confirm Taught All Selected
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="font-weight-bold mb-1" color="light-blue darken-4" dense :dark="!loadingBtnTaughtAll"
            :disabled="loadingBtnTaughtAll" @click="sendTaughtAllSlots">
            <v-progress-circular v-if="loadingBtnTaughtAll" :size="20" color="white" indeterminate
              class="mr-2"></v-progress-circular>
            Taught
          </v-btn>
          <v-btn @click="closeAllTaught" class="font-weight-bold mb-1" color="" dense dark>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog - B -->
    <v-dialog v-model="oaaDialog" :width="550" :retain-focus="false" persistent>
      <v-card>
        <v-card-text class="text-left text-h4 mt-0 mb-0 grey lighten-2 font-weight-bold">
          Write remark to OAA
        </v-card-text>
        <v-card-text>
          <span class="text-h5 mt-0 mb-0 font-weight-bold">Remark</span>
          <v-textarea v-model="inputOaa" outlined></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="font-weight-bold mb-1" color="light-blue darken-4" dense :dark="!loadingBtnAddRemark"
            :disabled="loadingBtnAddRemark" @click="sendDataRemarkOaa">
            <v-progress-circular v-if="loadingBtnAddRemark" :size="20" color="white" indeterminate
              class="mr-2"></v-progress-circular>
            Submit
          </v-btn>
          <v-btn @click="closeAddOaaRemark" class="font-weight-bold mb-1" color="" dense dark>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar text right top v-model="snackbarShow.view" :timeout="snackbarShow.time" :color="snackbarShow.color">
      <span class="text-h5 black--text mt-0 mb-0"><v-icon color="black" class="mr-2">{{ snackbarShow.icon }}</v-icon>{{
        snackbarShow.msg }}</span>
    </v-snackbar>
  </div>
</template>

<script>
const snackbar = {
  view: false,
  time: 2000,
  color: "",
  msg: "",
  icon: "",
};
const Makup = {
  type: "",
  dateMakeup: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10),
  selectHour_start: "",
  selectHour_end: "",
  selectMinute_start: "",
  selectMinute_end: "",
  roomId: "",
  remarkAdd: "",
};
export default {
  props: {
    loadingTableTimeSolt: {
      type: Boolean,
      default: () => "",
    },
    serverTime: {
      type: String,
      default: () => "",
    },
    teachingType: {
      type: Array,
      default: () => [],
    },
    timeslots: {
      type: Array,
      default: () => [],
    },
    loadValue: {
      type: Array,
      default: () => [],
    },
    paymentValue: {
      type: Array,
      default: () => [],
    },
    arrMsgBlock: {
      type: Array,
      default: () => [],
    },
    arrCurrentTime: {
      type: Object,
      default: () => { },
    },
    selYear: {
      type: Number,
      default: () => "",
    },
    switchShowTerm: {
      type: Number,
      default: () => "",
    },
  },
  data() {
    return {
      selectedTaught: [],
      dialogAllTaught: false,
      loadingBtnTaughtAll: false,
      valueSetting: [],
      dialogTimeslot: false,
      errorMsg: null,
      errorMsgsData: [],
      snackbarShow: Object.assign({}, snackbar),
      dataConfirm: {},
      formMakeup: Object.assign({}, Makup),
      arrRoom: [],
      arrHour: [
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
      ],
      // เริ่ม 00 , 30 จบ 20 , 50
      arrMinuteStart: ["00", "30"],
      arrMinuteEnd: ["20", "50"],
      menu: false,
      menu_timestart: false,
      menu_DateEnd: false,
      menu_timeend: false,
      headers: [
        {
          text: "",
          align: "center",
          value: "select-data",
          width: "5%",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "number",
          width: "",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "Details",
          align: "start",
          value: "details",
          width: "25%",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "Type",
          align: "center",
          value: "type",
          width: "",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "Room",
          align: "start",
          value: "room",
          width: "30%",
          class: "font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          width: "",
          class:
            "font-weight-bold black--text text-h5 blue-grey lighten-5 text-center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          value: "action",
          width: "20%",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "Remark to OAA only",
          align: "center",
          value: "remark",
          width: "25%",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
        {
          text: "#",
          align: "center",
          value: "configRow",
          width: "5%",
          class:
            "text-center font-weight-bold black--text text-h5 blue-grey lighten-5",
          sortable: false,
        },
      ],
      colorDay: "",
      loadingBtnAc: false,
      ArrPrint: [],
      menu1: false,
      isLoading: false,
      // remark to oaa
      loadingBtnAddRemark: false,
      inputOaa: "",
      oaaDialog: false,
      objRemarkOaa: {},
      // teaching record
      dialogTeachingRecord: false,
      objMsgCase: [],
      // check room
      isBlockRoom: true,
      // change time slot
      newLectureChange: null,
      optionLecture: [],
      dialogChangeTimeSlot: false,
      loadingBtnUpdateSlot: false,
      objSlotDataForUpdate: {},
      errorMsgCaseUpdateSlot: [],
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.formMakeup.dateMakeup
        ? this.moment(this.formMakeup.dateMakeup).format("DD/MM/YYYY")
        : "";
    },
    dateMakeup() {
      return this.formMakeup.dateMakeup;
    },
    isFormMakup() {
      return this.formMakeup.type !== "" &&
        this.formMakeup.dateMakeup !== "" &&
        this.formMakeup.selectHour_start !== "" &&
        this.formMakeup.selectHour_end !== "" &&
        this.formMakeup.selectMinute_start !== "" &&
        this.formMakeup.selectMinute_end !== "" &&
        this.formMakeup.roomId !== ""
        ? true
        : false;
    },
    selectAllTaught: {
      get() {
        return this.selectedTaught.length;
      },
      set(value) {
        this.selectedTaught = [];
        // console.log(this.$store.state.role.RulesUser.username)
        // console.log(this.timeslots[0].sectionSlots)
        // this.diffDayClick(
        //         this.moment().locale("th").format("LT"),
        //         this.moment(this.timeslots[0].sectionSlots[a].date).format(
        //           "YYYY-MM-DD"
        //         ),
        //         this.moment(this.serverTime).format("YYYY-MM-DD"),
        //         this.timeslots[0].sectionSlots[a].startTime, this.timeslots[0].sectionSlots[a].endTime
        //       )
        if (value) {
          for (let a = 0; a < this.timeslots[0].sectionSlots.length; a++) {
            if (
              this.timeslots[0].sectionSlots[a].instructor !== null &&
              this.$store.state.role.RulesUser.username ===
              this.timeslots[0].sectionSlots[a].instructor.instructorCode &&
              this.timeslots[0].sectionSlots[a].status === "w" &&
              this.timeslots[0].sectionSlots[a].teachingType !== "Midterm" &&
              this.timeslots[0].sectionSlots[a].teachingType !== "Final" &&
              this.timeSlotDayClick(this.timeslots[0].sectionSlots[a].date, this.timeslots[0].sectionSlots[a].startTime, this.timeslots[0].sectionSlots[a].endTime, this.serverTime)
              // this.diffDayClick(
              //   this.moment().locale("th").format("LT"),
              //   this.moment(this.timeslots[0].sectionSlots[a].date).format(
              //     "YYYY-MM-DD"
              //   ),
              //   this.moment(this.serverTime).format("YYYY-MM-DD"),
              //   this.timeslots[0].sectionSlots[a].startTime, this.timeslots[0].sectionSlots[a].endTime
              // )
            ) {
              this.selectedTaught.push(
                `${this.timeslots[0].sectionSlots[a].sectionId}::${this.timeslots[0].sectionSlots[a].sectionSlotId}::${this.timeslots[0].sectionSlots[a].date}`
              );
            }
          }
        }
      },
    },
  },
  watch: {
    timeslots(val) {
      this.selectedTaught = [];
    },
    dateMakeup(val) {
      this.getRoomMakeup();
    },
    // 'formMakeup.roomId'(val) {
    //   // console.log(val)
    //   // -----> new func
    //   this.checkMakeUpForAdBuild(
    //     this.formMakeup,
    //     this.formMakeup.dateMakeup,
    //     `${this.formMakeup.selectHour_start}:${this.formMakeup.selectMinute_start}:00`,
    //     `${this.formMakeup.selectHour_end}:${this.formMakeup.selectMinute_end}:00`,
    //     val
    //   )
    //   // -----> new func
    // }
  },
  mounted() { },
  methods: {
    checkAfterBtnTaughtAll(curY, curT, selY, selT) {
      if (parseInt(selY) > parseInt(curY)) {
        return true;
      } else if (parseInt(selY) == parseInt(curY)) {
        if (parseInt(selT) >= parseInt(curT)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // check roles
    checkGrouptest(arrGroup) {
      if (arrGroup.length != 0) {
        for (let check = 0; check < arrGroup.length; check++) {
          if (arrGroup[check].name === "InstructorTpTester") {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    // checkcolorDay
    colorDayCheck(dayText) {
      let arrColor = [
        { color: "red lighten-4", text: "SUN" },
        { color: "deep-purple lighten-4", text: "SAT" },
        { color: "yellow lighten-4", text: "MON" },
        { color: "pink lighten-4", text: "TUE" },
        { color: "teal lighten-4", text: "WED" },
        { color: "orange lighten-4", text: "THU" },
        { color: "light-blue lighten-4", text: "FRI" },
      ];
      return arrColor.find((text) => text.text === dayText).color;
    },
    // period check
    Daycheck(TimeShow) {
      if (TimeShow !== "" && TimeShow !== null) {
        let ts = this.moment().format();
        let d = this.moment().utc(ts);
        let time = TimeShow;
        let [h, m] = time.split(":");
        let e = d.clone().hours(h).minutes(m).seconds(0).milliseconds(0);
        return d.isBefore(e);
      } else {
        return true;
      }
    },
    timeSlotDayClick(dataSlot, timeStart, timeEnd, timeServer) {
      let slotDate = this.moment(dataSlot).locale('th').format("YYYY-MM-DD")
      let dateServer = this.moment(timeServer).locale('th').format('YYYY-MM-DD')
      var currentTime = this.moment(this.moment(timeServer).locale('th').format('LT'), "HH:mm");
      var startTime = this.moment(timeStart, "HH:mm");
      var endTime = this.moment(timeEnd, "HH:mm");
      if (![null, '', 'null'].includes(dataSlot)) {
        if (!this.moment(dateServer).isAfter(slotDate)) {
          if (this.moment(dateServer).isSame(slotDate)) {
            // return currentTime.isAfter(startTime) || currentTime.isBefore(endTime)
            // console.log(startTime , endTime, currentTime,currentTime.isBefore(endTime))
            return !currentTime.isBefore(startTime) && !currentTime.isBefore(endTime)
          }
          else {
            return false;
          }
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    // new ---->
    diffDayClick(chkTime, da, db, ta, tb) {
      var currentTime = this.moment(this.moment(chkTime).locale('th').format('LT'), "HH:mm");
      var endTime = this.moment(tb, "HH:mm");
      if (chkTime !== "" && da !== "") {
        if (!this.moment(db).isAfter(da)) {
          if (this.moment(db).isBefore(da)) {
            return false;
          } else {
            if (!this.Daycheck(chkTime)) {
              return endTime.isBefore(currentTime);
            } else {
              return false;
            }
          }
        } else {
          if (this.moment(db).isSame(da)) {
            if (!this.Daycheck(chkTime)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    // call action to api
    openDialog(objData) {
      this.loadingBtnAc = false;
      this.dataConfirm = Object.assign({}, objData);
      this.dialogTimeslot = true;
      this.formMakeup = Object.assign({}, Makup);
      this.errorMsg = null;
      this.arrRoom = [];
    },
    closeDialog() {
      this.loadingBtnAc = false;
      this.dataConfirm = Object.assign({}, {});
      this.formMakeup = Object.assign({}, Makup);
      this.errorMsg = null;
      this.dialogTimeslot = false;
    },
    openAddOaaRemark(objData) {
      this.loadingBtnAddRemark = false;
      this.inputOaa = "";
      this.objRemarkOaa = Object.assign({}, objData);
      this.oaaDialog = true;
    },
    closeAddOaaRemark() {
      this.oaaDialog = false;
      this.objRemarkOaa = Object.assign({}, {});
      this.inputOaa = "";
      this.loadingBtnAddRemark = false;
    },
    // set Taught , cancel Class in slot
    async setSlot(secId, slotId, status, dataSlot, teachingT) {
      // this.timeslots[0].termText.split("/")[0]
      this.errorMsgsData = [];
      this.loadingBtnAc = true;
      this.errorMsg = null;
      let urlSetslot = "";
      try {
        if (teachingT === "Midterm" || teachingT === "Final") {
          urlSetslot = `/keystone/section/taught_exam/?exam_id=${slotId}&section_id=${secId}&slot_time=${dataSlot}&status=${status}&term_id=${parseInt(
            this.timeslots[0].termText.split("/")[0]
          )}`;
        } else {
          urlSetslot =
            status === "c"
              ? `/keystone/section/cancel_class/?id_slot=${slotId}`
              : `/keystone/section/taught_class/?id_slot=${slotId}&section_id=${secId}&slot_time=${dataSlot}&term_id=${parseInt(
                this.timeslots[0].termText.split("/")[0]
              )}`;
        }
        const setSlot = await this.$http.get(urlSetslot);
        if (setSlot.status === 200) {
          this.$emit("updateSlot", { secId, slotId, status, teachingT });
          // clear
          this.selectedTaught = [];
          // clear
          this.loadingBtnAc = false;
          this.closeDialog();
          Object.assign(this.snackbarShow, {
            view: true,
            time: 2000,
            color: "primary",
            msg:
              status === "c"
                ? "Timeslot cancelled successfully"
                : "Set taught successfully",
            icon: "mdi-playlist-check",
          });
        } else {
          throw { setSlot };
        }
      } catch (error) {
        this.loadingBtnAc = false;
        this.errorMsgsData.push({
          timestamp: new Date().getTime(),
          msgstatus: error.response.status,
          msgstatusText: error.response.statusText,
          msg: Object.values(error.response.data)[0],
        });
        if (this.errorMsgsData.length > 1) this.errorMsgsData.shift();
        this.errorMsg = `Caught api ${urlSetslot} connect API`;
      }
    },
    openAllTaught() {
      this.loadingBtnTaughtAll = false;
      this.dialogAllTaught = true;
    },
    closeAllTaught() {
      this.loadingBtnTaughtAll = false;
      this.dialogAllTaught = false;
    },
    async sendTaughtAllSlots() {
      this.loadingBtnTaughtAll = true;
      for (let TaughtA = 0; TaughtA < this.selectedTaught.length; TaughtA++) {
        let status = "p";
        let secId = this.selectedTaught[TaughtA].split("::")[0];
        let slotId = this.selectedTaught[TaughtA].split("::")[1];
        try {
          const setAllSlot = await this.$http.get(
            `/keystone/section/taught_class/?id_slot=${this.selectedTaught[TaughtA].split("::")[1]
            }&section_id=${this.selectedTaught[TaughtA].split("::")[0]
            }&slot_time=${this.selectedTaught[TaughtA].split("::")[2]
            }&term_id=${parseInt(this.timeslots[0].termText.split("/")[0])}`
          );
          if (setAllSlot.status === 200) {
            this.$emit("updateSlotAll", { secId, slotId, status });
          } else {
            throw { setAllSlot };
          }
        } catch (error) {
          this.loadingBtnTaughtAll = false;
        }
      }
      this.selectedTaught = [];
      this.closeAllTaught();
      this.loadingBtnTaughtAll = false;
    },
    // makeup class
    async getRoomMakeup() {
      this.isLoading = true;
      this.errorMsg = null;
      this.formMakeup.roomId = '';
      if (
        this.formMakeup.dateMakeup !== "" &&
        this.formMakeup.selectHour_start !== "" &&
        this.formMakeup.selectHour_end !== "" &&
        this.formMakeup.selectMinute_start !== "" &&
        this.formMakeup.selectMinute_end !== ""
      ) {
        // let dataPreArr = [];
        this.arrRoom = [];
        try {
          const resRoom = await this.$http.get(
            `/keystone/section/get_rooms/?date=${this.formMakeup.dateMakeup}&start_time=${this.formMakeup.selectHour_start}:${this.formMakeup.selectMinute_start}:00&end_time=${this.formMakeup.selectHour_end}:${this.formMakeup.selectMinute_end}:00`
          );
          if (resRoom.status === 200) {
            // this.arrRoom = dataPreArr
            // console.log(this.moment(this.formMakeup.dateMakeup).format("dd"))
            this.arrRoom = resRoom.data.data
              .filter((cBuild) => (['Sa', 'Su'].includes(this.moment(this.formMakeup.dateMakeup).format("dd")) && this.formMakeup.selectHour_end > 16) ? cBuild.building !== 'Aditayathorn Building' : true)
              .map((item) => {
                return {
                  text: `${item.nameEn === "No Classroom"
                    ? "No used / No Classroom"
                    : item.nameEn
                    } ${item.building} Floor: ${item.floor}`,
                  value: item.roomId,
                  building: item.building,
                  capacity: item.capacity,
                  examinationCapacity: item.examinationCapacity,
                  floor: item.floor,
                  roomType: item.roomType,
                  sortOnline: `${item.isOnline}`,
                };
              });
            this.arrRoom.sort(function (a, b) {
              var nameA = a.sortOnline.toUpperCase();
              var nameB = b.sortOnline.toUpperCase();
              if (nameA > nameB) {
                return -1;
              }
              if (nameA < nameB) {
                return 1;
              }
              return 0;
            });
            this.isLoading = false;
          } else {
            throw { resRoom };
          }
        } catch (error) {
          this.isLoading = false;
          this.errorMsg = `Caught api connect API : /keystone/section/get_rooms/?date=${this.formMakeup.dateMakeup}&start_time=${this.formMakeup.selectHour_start}:${this.formMakeup.selectMinute_start}:00&end_time=${this.formMakeup.selectHour_end}:${this.formMakeup.selectMinute_end}:00`;
        }
      } else {
        this.errorMsg = null;
      }
    },
    async submitMakeupClass(idSlotMakeUp) {
      this.errorMsg = null;
      this.loadingBtnAc = true;
      this.objMsgCase = [];
      try {
        const sendSetNewSlot = new FormData();
        sendSetNewSlot.append("SectionId", idSlotMakeUp);
        sendSetNewSlot.append("TeachingTypeId", this.formMakeup.type);
        sendSetNewSlot.append("RoomId", this.formMakeup.roomId);
        sendSetNewSlot.append("Date", this.formMakeup.dateMakeup);
        sendSetNewSlot.append(
          "StartTime",
          `${this.formMakeup.selectHour_start}:${this.formMakeup.selectMinute_start}:00`
        );
        sendSetNewSlot.append(
          "EndTime",
          `${this.formMakeup.selectHour_end}:${this.formMakeup.selectMinute_end}:00`
        );
        sendSetNewSlot.append("Remark", this.formMakeup.remarkAdd);
        const classMakeup = await this.$http.post(
          `/keystone/section/makeup_class/`,
          sendSetNewSlot,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (classMakeup.status === 200) {
          if (classMakeup.data.code === "200") {
            this.formMakeup = Object.assign({}, Makup);
            this.$emit("newSlot", classMakeup.data.data);
            this.loadingBtnAc = false;
            this.selectedTaught = [];
            this.closeDialog();
          } else {
            this.objMsgCase.push(classMakeup.data);
            this.loadingBtnAc = false;
          }
        } else {
          throw { classMakeup };
        }
      } catch (error) {
        this.objMsgCase = [];
        this.loadingBtnAc = false;
        this.errorMsg = `Caught api connect API : /keystone/section/makeup_class/`;
      }
    },
    async sendDataRemarkOaa() {
      // console.log(this.inputOaa);
      // console.log(this.objRemarkOaa);
      this.$emit("updateSlotRemark", {
        textadd: this.inputOaa,
        detail: this.objRemarkOaa,
      });
      this.closeAddOaaRemark();
    },
    gotoBill(paramMonth, paramYear) {
      this.$router.push({
        path: "/payment",
        query: { month: paramMonth, year: paramYear },
      });
    },
    openModalUpdateTimeSlot(objData) {
      // console.log(objData)
      // console.log(this.timeslots[0])
      this.errorMsgCaseUpdateSlot = []
      this.loadingBtnUpdateSlot = false
      this.newLectureChange = null
      // set Option
      this.optionLecture = []
      for (let l = 0; l < this.timeslots[0].team_instructor.length; l++) {
        const element = this.timeslots[0].team_instructor[l];
        // console.log(element)
        this.optionLecture.push({
          value: `${element.instructor_code}`,
          text: `${element.instructor_detail[0].title.short_eng} ${element.instructor_detail[0].name} ${element.instructor_detail[0].surname}`,
        })
      }
      // set Option
      this.objSlotDataForUpdate = Object.assign({}, objData)
      this.dialogChangeTimeSlot = true
    },
    closeModalUpdateTimeSlot() {
      this.dialogChangeTimeSlot = false;
      this.errorMsgCaseUpdateSlot = []
      this.loadingBtnUpdateSlot = false
      this.newLectureChange = null
      this.objSlotDataForUpdate = Object.assign({}, {});
    },
    async updateLectureInSlot(val){
      // console.log('xxx',val)
      this.errorMsgCaseUpdateSlot = []
      this.loadingBtnUpdateSlot = true
      const sendSetNewLecture = new FormData();
      sendSetNewLecture.append('section_id', val.rowItem.sectionId)
      sendSetNewLecture.append('section_slot_id',  val.rowItem.sectionSlotId)
      sendSetNewLecture.append('new_lecturer_username', this.newLectureChange)
      sendSetNewLecture.append('academic_year', this.timeslots[0].termText.split("/")[1])
      sendSetNewLecture.append('academic_term', this.timeslots[0].termText.split("/")[0])
      try {
        const res = await this.$http.post(
          `keystone/section/update_class/`,
          sendSetNewLecture,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if([200,201].includes(res.status)){
          // console.log(res.data)
          this.$emit("updateSlotLecture", { 'responseRecive': res.data , 'val': val , 'dataChange': this.newLectureChange });
          this.closeModalUpdateTimeSlot()
          this.loadingBtnUpdateSlot = false
          this.errorMsgCaseUpdateSlot = []
        }
        else{
          throw { res }
        }
      } catch (error) {
        console.log(error)
        this.errorMsgCaseUpdateSlot.push(`Error (${val.rowItem.sectionId}),(${val.rowItem.sectionSlotId}) :: ${error.response.data.message}, keystone/section/update_class/`)
        this.loadingBtnUpdateSlot = false
        // this.closeModalUpdateTimeSlot()
      }
    },
    // checkMakeUpForAdBuild(
    //   fMakeup,
    //   dateMakeup,
    //   timeStart,
    //   timeEnd,
    //   roomID
    // ) {
    //   if (!['', null, "null"].includes(roomID)) {
    //     // this.isBlockRoom = false
    //     // console.log(this.isBlockRoom)
    //     // console.log(fMakeup)
    //     // console.log(dateMakeup)
    //     // console.log(timeStart)
    //     // console.log(timeEnd)
    //     // console.log(roomID)
    //     // step A : check Build
    //     for (let a = 0; a < this.arrRoom.length; a++) {
    //       const element = this.arrRoom[a];
    //       if(element.roomId === roomID){
    //         console.log(element.building)
    //       }
    //     }
    //   } else {
    //     this.isBlockRoom = true
    //   }
    // },
  },
};
</script>

<style scoped>
.f-sm {
  font-size: 12px;
  font-weight: 500;
}

.f-300 {
  font-weight: 500;
}

.text-r {
  white-space: pre-wrap;
}

.f-a-20 {
  font-size: 20px;
}

.f-a-18 {
  font-size: 18px;
}
</style>